@import './_reset.scss';

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url('../fonts/poppins-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: url('../fonts/poppins-medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: bold;
  src: url('../fonts/poppins-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  src: url('../fonts/poppins-italic.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #0a1f44;
  font-size: 14px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f0f3f5;
  height: calc(100% - 130px);
  -webkit-overflow-scrolling: touch;
}

a {
  text-decoration: none;
  color: #2962ff;
}

a:hover,
a:focus,
a:active {
  outline: none;
  text-decoration: none;
}

a:hover {
  color: #61dafb;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  white-space: normal;
}

b,
strong {
  font-weight: bold;
}

label {
  font-weight: 500;
  display: block;
}

img {
  max-width: 100%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

ul {
  margin: 0;
}

li {
  list-style-type: none;
}

#root {
  height: 100%;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.breadcrumbs {
  border-top: 1px solid #ccc;
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 1000;
}

#main {
  margin-top: 125px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1366px;
  width: 100%;
  min-height: 100%;
}

.content {
  padding: 20px;
  height: 100%;

  @media (max-width: 576px) {
    padding: 20px 0;
  }
}

.required-field::after {
  content: '*';
  color: red;
}

.color-link {
  color: #168cb7;
}

.pointer {
  cursor: pointer;
}
