//input
input.form-control {
  font-size: 14px;
}

//react-select
div.react-select {
  &__control {
    border-color: #ced4da;
    min-height: 35px;
    height: 35px;

    &--is-focused,
    &--menu-is-open {
      &:hover {
        border-color: #80bdff;
      }

      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem #007bff40;
    }

    &--is-disabled {
      background-color: #e9ecef;
    }
  }

  &__single-value {
    &--is-disabled {
      color: #495057;
    }
  }
}

.dropzone {
  position: relative;
  padding: 26px;
  height: 76px;
  margin-bottom: 10px;
  width: 100%;
  background-color: #fff;
  border: 2px dashed #dedede;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin: 0px;
  text-align: center;
}

.dropzone-item {
  background-color: #fff;
  border: 1px solid #dedede;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin: 0px;
  padding: 10px;
}
